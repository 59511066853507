import { createContext, useContext, useMemo, useState } from "react";
import { AUTH_ACCESS_TOKEN } from "../constants";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem(AUTH_ACCESS_TOKEN));

  const login = async (token) => {
    localStorage.setItem(AUTH_ACCESS_TOKEN, token)
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem(AUTH_ACCESS_TOKEN)
    setToken(null);
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
    }), // eslint-disable-next-line
    [token]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
