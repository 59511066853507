export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FALIED = "LOGIN_FALIED";
export const LOGOUT = "LOGOUT";

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const API_REQUEST = "API_REQUEST";
export const SUCCESS_RESPONSE = "SUCCESS_RESPONSE";
export const ERROR_RESPONSE = "ERROR_RESPONSE";
export const SET_COMMON_STATUS = "SET_COMMON_STATUS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const SET_COUNT_OF_NOTIFICATIONS = 'SET_COUNT_OF_NOTIFICATIONS';
