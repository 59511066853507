export const AUTH_ACCESS_TOKEN = 'access_token';
export const ROLES = ['USER']

export const _status = ["checking", "pending", "aproved", "canceled"];

export const SITES = [
    "ctc-associates.com",
    "adsprecise.com",
    "adstransitions.com",
    "dentaltrans.com",
    "fryepracticesales.com",
    "menlotransitions.com",
    "dgtransitions.com",
    "knutzenmcvaygroup.com",
    "mydentalbroker.com",
    "professionaltransition.com",
    "ddsmatch.com",
    "omni-pg.com",
    "westernpracticesales.com",
    "dentalpracticetransitions.henryschein.com"
]

export const LISTING_TYPES = [
    'Pedo',
    'Perio',
    'Ortho',
    'Endo',
    'General',
    'Surgery'
]
